














































































































/* eslint-disable global-require */
import { Component, Vue } from "vue-property-decorator";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import {
  BLink, BImg, BSpinner, BForm, BFormInput, BFormGroup, BButton, BCardText, BCardTitle, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import VuexyLogo from '@/@core/layouts/components/Logo.vue'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { confirmed, password, required } from "@/@core/utils/validations/validations";
import { UtilisateurValidateAccountModel } from "@/api/models/utilisateurs/utilisateur";
@Component({
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    BImg,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BCardTitle,
    BCardText,
    BCard,
    BInputGroup,
    BInputGroupAppend
  },
})
export default class ConfirmAccount extends Vue {
  required = required;
  password= password;
  confirmed = confirmed;
  
  payload: UtilisateurValidateAccountModel = {}
  loading = false

  passwordFieldType = "password"
  confirmPasswordFieldType = "password"
  
  created() {
    this.payload = {
      userId: this.$route.params.userId,
      emailToken: this.$route.params.emailToken,
      passwordToken: this.$route.params.passwordToken,
    }
  }
  
  get passwordToggleIcon() {
    return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  }
  
  get confirmPasswordToggleIcon() {
    return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  }

  async validate() {
    this.loading = true
    await this.$http.myressif.utilisateurs
      .confirmAccount(this.payload)
      .then((res: any) => {
        successAlert.fire({
          title: 'Confirmation de mon compte',
          text: 'Compté configuré avec succès',
        });

        setTimeout(() => {
          this.$router.push({ name: 'presentation'})
        }, 1500)
      },
      (error: any) => {
        this.loading = false
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      })
  }

  togglePasswordVisibility(){
    if (this.passwordFieldType === "password")
      this.passwordFieldType = "text"
    else 
      this.passwordFieldType = "password"
  }
  
  toggleConfirmPasswordVisibility(){
    if (this.confirmPasswordFieldType === "password")
      this.confirmPasswordFieldType = "text"
    else 
      this.confirmPasswordFieldType = "password"
  }
}
